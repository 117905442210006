import React from 'react';
import { List, ListSubheader, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { AuxiliarySuccess, PilabsBlue, Tooltip } from '@easerill/pixida-group-ui';

export interface ListData {
	id: string;
	primary?: string;
	secondary?: string;
	tooltipText?: string;
}

export interface ChangeHistoryListProps {
	title?: string;
	listData?: ListData[];
	selectedId?: string;
	onChangeSelection?: (selectedId: string) => void;
}

const ChangeHistoryList = ({ listData = [], title, selectedId, onChangeSelection }: ChangeHistoryListProps) => {
	const handleListItemClick = (id: string) => {
		if (onChangeSelection) {
			onChangeSelection(id);
		}
	};

	return (
		<List
			sx={{
				width: '100%',
				maxWidth: 360,
				bgcolor: 'transparent',
				maxHeight: 254,
				position: 'relative',
				overflow: 'auto',
				marginLeft: '-1px',
				marginTop: '2px',
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
			dense={true}
			subheader={
				<ListSubheader
					component="div"
					id="nested-list-subheader"
					sx={{ bgcolor: 'transparent', position: 'initial', borderBottom: 'solid 1px #c4c4c4' }}
				>
					<strong>{title}</strong>
				</ListSubheader>
			}
		>
			{listData?.map((listData: ListData) => {
				const selected = selectedId === listData.id;
				return (
					<ListItem
						disablePadding
						sx={{
							bgcolor: selected ? 'background.paper' : undefined,
							borderBottom: 'solid 1px #c4c4c4',
							borderLeft: selected ? undefined : 'solid 1px #c4c4c4',
						}}
					>
						<ListItemButton
							onClick={() => handleListItemClick(listData.id)}
							sx={{ paddingLeft: selected ? '0px' : undefined }}
						>
							{selectedId === listData.id ? (
								<ListItemIcon sx={{ minWidth: '30px' }}>
									<ChevronLeft sx={{ color: PilabsBlue[600] }} />
								</ListItemIcon>
							) : undefined}
							<Tooltip title={listData.tooltipText || ''} enterDelay={800} leaveDelay={10}>
								<ListItemText
									primary={listData.primary}
									primaryTypographyProps={{
										color: selected ? PilabsBlue[600] : undefined,
										sx: selected ? { fontWeight: 'bold' } : undefined,
									}}
									secondary={listData.secondary}
									secondaryTypographyProps={{
										noWrap: true,
										fontSize: 12,
										lineHeight: '16px',
										color: AuxiliarySuccess[800],
										sx: { fontWeight: 'bold' },
									}}
								/>
							</Tooltip>
						</ListItemButton>
						{selected ? (
							<Box
								bgcolor={PilabsBlue[600]}
								width={'12px'}
								height={'100%'}
								position={'absolute'}
								right={0}
							/>
						) : undefined}
					</ListItem>
				);
			})}
		</List>
	);
};

export default ChangeHistoryList;
