import { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Layout from '../layout/Layout';
import Loading from '../pages/Loading';
import { Button, TextField, InputAdornment } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { ColdNeutral } from '@easerill/pixida-group-ui';
import Spinner from '../accessories/Spinner';
import { SaveWhiteIcon } from '../material-table/icons';
import { toast } from 'react-toastify';
import { uploadProtocolAction } from '../../actions/ProtocolActions';
import { useDispatch } from 'react-redux';

const VIN_REGEX = /^[(A-H|J-N|P|R-Z|0-9)]{17}$/;

const useStyles = makeStyles((theme: any) => ({
	container: {
		width: '100%',
		height: '100%',
		'& form': {
			borderRadius: '3px',
			boxShadow: '0px 1px 3px 0px #00000033',
			background: 'white',
			padding: '1rem',
		},
		'& h1': {
			color: '#616b70',
			fontWeight: 300,
			fontSize: 'min(6vw, 34px)',
		},
	},
	pageTitle: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	loading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		backgroundColor: 'rgba(255, 255, 255, 0.7)',
		zIndex: 2,
	},
	fileUpload: {
		position: 'relative',
	},
	textField: {
		marginBottom: '1rem !important',
		'& :focus': {
			opacity: 1,
		},
		'& input[type="file"]': {
			opacity: 0,
		},
	},
	uploadBtnCls: {
		marginRight: '8rem',
		cursor: 'pointer',
	},
	uploadBtn: {
		position: 'absolute',
		right: '.5rem',
		top: '.5rem',
		textTransform: 'uppercase',
		display: 'flex',
		padding: '.7rem',
		background: ColdNeutral[200],
		color: '#ffffff',
		cursor: 'default',
		borderRadius: '.25rem',
	},
	bottomArea: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
	},
	cancelButton: {
		marginRight: '1rem !important',
	},
}));

const toastConfig = {
	autoClose: 3000,
	position: toast.POSITION.BOTTOM_LEFT,
};

interface Protocol {
	file?: any;
	filename?: string;
	vin?: string;
	vinConfirmation?: string;
	serialNumber?: string;
	remarks?: string;
}

const UploadProtocol = () => {
	const classes = useStyles();

	const [protocol, setProtocol] = useState<Protocol>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(true);
	const fileRef = useRef<HTMLDivElement>(null);
	const vinRef = useRef<HTMLDivElement>(null);
	const uploadBtnRef = useRef<HTMLButtonElement>(null);
	const remarksRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (canEdit && protocol.file) {
			vinRef.current && vinRef.current.focus();
		}
	}, [canEdit, protocol.file]);

	function wasSuccessfullyInstalled(protocolFileContent: Buffer): boolean {
		const content = protocolFileContent.toString('utf-8');
		const lines = content.split(/\r?\n/);
		let fmsStatus: boolean | undefined;
		let isiStatus: boolean | undefined;
		for (const line of lines) {
			if (line.startsWith('FMS:')) {
				fmsStatus = line === 'FMS: working';
				continue;
			}

			if (line.startsWith('ISI:')) {
				isiStatus = line === 'ISI: working';
				continue;
			}

			if (fmsStatus !== undefined && isiStatus !== undefined) {
				break;
			}
		}

		return fmsStatus !== undefined && isiStatus !== undefined ? fmsStatus && isiStatus : false;
	}

	const handleFileUpload = async ({ target }: any) => {
		const file = target.files[0];
		if (fileRef.current?.children && file?.name) {
			resetForm();
			try {
				const data = await file.text();
				const lines = data.split(/\r?\n/);

				const snAndVin = lines.filter(
					(l: string) =>
						l.includes('VIN:') ||
						l.includes('SN:'),
				);

				if (snAndVin.length === 2) {
					let vin: string | undefined = undefined;
					let serialNumber: string | undefined = undefined;
					snAndVin.forEach((info: string) => {
						const [key, value] = info.split(':').map((item) => item.trim());
						if (key === 'VIN') {
							if (VIN_REGEX.test(value)) {
								vin = value;
							}
						} else if (key === 'SN') {
							if (/^[A-Za-z0-9]{2}?-([A-Za-z0-9]{2})?-([A-Fa-f0-9]{2})-(\d{7})$/.test(value)) {
								serialNumber = value;
							} else {
								throw new Error('Invalid serial number');
							}
						}
					});

					setProtocol((prev) => ({
						...prev,
						file,
						filename: file.name,
						vin,
						serialNumber,
					}));
					setCanEdit(true);
				} else {
					throw new Error('Invalid protocol file');
				}

				const isSuccess = wasSuccessfullyInstalled(data);
				setSuccess(isSuccess);
				if (!isSuccess && !protocol.remarks) {
					toast.error(`Warn: You need to fill the remarks for this protocol`, toastConfig);
				}
			} catch (err) {
				toast.error(`Error: ${(err as Error).message}`, toastConfig);
				if (fileRef.current) {
					(fileRef.current.children[1].children[0] as HTMLInputElement).value = '';
				}
				setCanEdit(false);
				setProtocol({});
			}
		} else {
			setProtocol({});
		}
	};

	const openFileSelector = () => {
		if (fileRef.current) {
			(fileRef.current.children[1].children[0] as HTMLInputElement).click();
		}
	};

	const resetForm = () => {
		if (fileRef.current) {
			(fileRef.current.children[1].children[0] as HTMLInputElement).value = '';
		}
		if (remarksRef && remarksRef.current) {
			remarksRef.current.value = '';
		}
		setCanEdit(false);
		setProtocol({});
		setSuccess(true);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setLoading(true);

		const data = {
			vin: protocol.vin,
			serialNumber: protocol.serialNumber,
			remarks: protocol.remarks,
		};
		const form = new FormData();
		form.append('data', JSON.stringify(data));
		form.append('protocol', protocol.file, protocol.filename);
		dispatch<any>(uploadProtocolAction(form))
			.then(() => {
				toast.success(`Protocol successfully uploaded!`, toastConfig);
				resetForm();
			})
			.catch(() => {
				toast.error('Upload protocol failed!', toastConfig);
			})
			.finally(() => setLoading(false));
	};

	function handleRemarks(event: any) {
		const remarks = event.target.value;
		setProtocol((prev) => ({ ...prev, remarks }));
	}

	return (
		<Layout>
			<div className={classes.container}>
				<div className={classes.pageTitle}>
					<h1>Installation Protocol Upload</h1>
				</div>
				<form onSubmit={handleSubmit}>
					{loading ? (
						<div className={classes.loading}>
							<Loading />
						</div>
					) : null}
					<div className={classes.fileUpload}>
						<TextField
							ref={fileRef}
							className={classes.textField}
							variant="outlined"
							fullWidth={true}
							type="file"
							label={protocol.filename || 'Upload file'}
							InputLabelProps={{ shrink: false }}
							name="codeFile"
							inputProps={{ accept: '.txt' }}
							helperText="Please upload a protocol text file"
							onChange={handleFileUpload}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="start"
										className={classes.uploadBtnCls}
										onClick={() => resetForm()}
									>
										<Clear />
									</InputAdornment>
								),
							}}
						/>
						<div className={classes.uploadBtn} onClick={openFileSelector}>
							Select file
						</div>
					</div>
					<TextField
						className={classes.textField}
						variant="outlined"
						fullWidth={true}
						label="Serial Number"
						name="serialNumber"
						value={protocol.serialNumber || ''}
						disabled={true}
						helperText={'Serial number of the device'}
					/>
					<TextField
						className={classes.textField}
						inputRef={vinRef}
						variant="outlined"
						fullWidth={true}
						label="VIN"
						name="vin"
						value={protocol.vin || ''}
						disabled={!canEdit}
						helperText={'Vehicle identification number'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setProtocol((prev) => ({
								...prev,
								vin: event.target.value,
							}));
						}}
					/>
					<TextField
						className={classes.textField}
						variant="outlined"
						fullWidth={true}
						label="VIN confirmation"
						name="vinConfirmation"
						value={protocol.vinConfirmation ? protocol.vinConfirmation : ''}
						disabled={!canEdit}
						helperText={'Please repeat the VIN'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setProtocol((prev) => ({
								...prev,
								vinConfirmation: event.target.value,
							}));
						}}
					/>
					<TextField
						multiline
						minRows={4}
						id="installationRemarks"
						name="installationRemarks"
						label="Installation remarks"
						helperText="For example: Why did the installation fail? What did you do when the installation check was displaying an error?"
						className={classes.textField}
						fullWidth={true}
						variant="outlined"
						inputProps={{ maxLength: 500 }}
						value={protocol.remarks ? protocol.remarks : ''}
						onChange={handleRemarks}
						error={!success && !protocol.remarks}
						ref={remarksRef}
						disabled={!canEdit}
					/>
					<div className={classes.bottomArea}>
						<Button onClick={() => resetForm()} color="primary" className={classes.cancelButton}>
							Clear
						</Button>
						<Button
							type="submit"
							startIcon={loading ? <Spinner primaryColor="white" size={1.5} /> : <SaveWhiteIcon />}
							color="primary"
							variant="contained"
							ref={uploadBtnRef}
							disabled={
								!(
									protocol.file &&
									protocol.serialNumber &&
									protocol.vin &&
									VIN_REGEX.test(protocol.vin) &&
									protocol.vin === protocol.vinConfirmation &&
									(success || (!success && protocol.remarks))
								)
							}
						>
							Upload
						</Button>
					</div>
				</form>
			</div>
		</Layout>
	);
};

export default UploadProtocol;
