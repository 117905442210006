import React, { ReactElement } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	DialogActions,
	DialogProps,
	DialogContentText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
});

type ConfirmDialogProps = {
	content: string | ReactElement;
	handleClose: (answer: string) => void;
} & DialogProps;

const ConfirmDialog = ({ handleClose, open, content, title }: ConfirmDialogProps) => {
	const classes = useStyles();

	const onClose = async ({ currentTarget: target }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		handleClose(target.name);
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button name="yes" className={classes.buttons} onClick={onClose} color="primary" size="small">
					Yes
				</Button>
				<Button
					name="no"
					className={classes.buttons}
					onClick={onClose}
					color="primary"
					size="small"
					variant="contained"
					style={{ color: '#ffffff' }}
				>
					No
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
