import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	DialogActions,
	DialogProps,
	DialogContentText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
});

type AlertDialogProps = {
	textContent: string;
	handleClose: () => void;
} & DialogProps;

const AlertDialog = (props: AlertDialogProps) => {
	const classes = useStyles();

	const onClose = async () => {
		props.handleClose();
	};

	return (
		<Dialog {...props} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<pre>{props.textContent}</pre>
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center' }}>
				<Button
					name="ok"
					className={classes.buttons}
					onClick={onClose}
					color="primary"
					size="small"
					variant="contained"
					style={{ color: '#ffffff' }}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
