import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PilabsButton from '../accessories/PilabsButton';
import Layout from '../layout/Layout';
import IStore from '../../store/IStore';
import User from '../../model/User';
import IoTGWTopview from '../../assets/IoT-GW-topview.png';
import { signOut } from '../../actions/AuthActions';
import { useNavigate as useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

const HomePage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	return (
		<Layout>
			<div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: '50% 50%', width: '100%' }}>
				<div
					style={{
						display: 'grid',
						padding: '5rem 0',
						height: 'auto',
						alignItems: 'right',
						width: '100%',
					}}
				>
					<div style={{ textAlign: 'right' }}>
						<img
							src={IoTGWTopview}
							style={{ maxWidth: 'calc(100% - 5rem)', margin: '0 5rem 0 0' }}
							alt={'IoT Gateway device'}
						/>
					</div>
				</div>
				<div
					style={{
						display: 'grid',
						padding: '5rem 0',
						height: 'auto',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<Typography variant="h1">Adaptive Data Hub</Typography>&nbsp;
					<br />
					<span>
						Email: <b>{user?.email}</b>
					</span>
					&nbsp;
					<br />
					<span>
						Customer: <b>{user?.account}</b>
					</span>
					&nbsp;
					<br />
					<span>
						Role: <b>{user?.groups.join(', ')}</b>
					</span>
					&nbsp;
					<br />
					<div
						style={{
							maxWidth: 'fit-content',
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							columnGap: '1rem',
						}}
					>
						<PilabsButton onClick={() => history('/groups/create')}>Create Group</PilabsButton>
						<PilabsButton onClick={() => dispatch(signOut())}>Logout</PilabsButton>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default HomePage;
