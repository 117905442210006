import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import logo from '../../assets/logo.png';
import { routes } from '../router/Routes';
import ProfileMenu from '../auth/ProfileMenu';
import { makeStyles } from '@mui/styles';

import { Container } from '@mui/material';

interface IHeaderProps {
	children?: React.ReactNode[];
}

const useStyles = makeStyles((theme: any) => ({
	header: {
		'& * a': {
			textDecoration: 'none',
		},
		flexShrink: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '100%',
		backgroundColor: 'white',
		boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.14)',
	},
	greyText: {
		fontWeight: 600,
		fontSize: '0.75rem',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: 'var(--trip-text-gray)',
	},
	headerNav: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '3.5rem',
		margin: 'auto',
		width: '100%',
		maxWidth: '75rem',
	},
	headerNavLinks: {
		display: 'flex',
		width: '70%',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	headerLinkContainer: {
		display: 'flex',
		height: '100%',
		overflow: 'auto',
	},
	headerLink: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: `transparent solid 4px`,
		color: theme.palette.grey['500'],
		fontWeight: 500,
		letterSpacing: '1.25px',
		transition: 'color .3s linear',
		'&:not(:first-child)': {
			marginLeft: '1.875rem',
		},
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&.header-selected': {
			color: theme.palette.primary.main,
			borderBottom: `${theme.palette.primary.main} solid 4px`,
		},
	},
	headerLogo: {
		marginTop: '5%',
	},
}));

const Header: FC<IHeaderProps> = function () {
	const classes = useStyles();
	const selectedClass = `${classes.headerLink} ${classes.greyText} header-selected`;
	const notSelectedClass = `${classes.headerLink} ${classes.greyText}`;
	const { pathname: path } = useLocation();
	const userGroups = useSelector<IStore, string[]>((state) => state.user.info?.groups || []);

	return (
		<header className={classes.header}>
			<Container>
				<nav className={classes.headerNav}>
					<Link to={'/'}>
						<img className={classes.headerLogo} src={logo} alt={"PI Labs's logo"} />
					</Link>
					<div className={classes.headerLinkContainer}>
						{Object.values(routes).map((v) =>
							v.roles.some((r) => userGroups.includes(r)) ? (
								<Link
									key={v.uri}
									className={`/${path.split('/')[1]}` === v.uri ? selectedClass : notSelectedClass}
									to={v.uri}
								>
									{v.label}
								</Link>
							) : null,
						)}
						<ProfileMenu />
					</div>
				</nav>
			</Container>
		</header>
	);
};

export default Header;
