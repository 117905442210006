import React from 'react';
import Layout from '../layout/Layout';
import { Button, TextField, InputAdornment } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate as useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmDialog from './ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { createGroupAction, listGroupsAction, updateGroupAction } from '../../actions/GroupActions';
import IStore from '../../store/IStore';
import Group, { Version } from '../../model/Group';
import { RemoveWhiteIcon, SaveWhiteIcon } from '../material-table/icons';
import Ajv from 'ajv';
import configSchema from '../../configSchema.json';
import Spinner from '../accessories/Spinner';
import { deleteGroup, getGroup, getConfigurationVersion } from '../../api/groups';
import Loading from '../pages/Loading';
import { Clear } from '@mui/icons-material';
import newGroupConfigurationTemplate from './new-group-configuration-template.json';
import { makeStyles } from '@mui/styles';
import { ColdNeutral } from '@easerill/pixida-group-ui';
import ajvErrors from 'ajv-errors';
import ajvKeywords from 'ajv-keywords';
import ChangeHistoryList, { ListData } from '../change-history-list/ChangeHistoryList';
import moment from 'moment';
import dateFormat from 'dateformat';

const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);
ajvKeywords(ajv, 'regexp');

const cap = (word: string) => word[0].toUpperCase() + word.substring(1);
const isEmpty = (value: string) => !/\S.*/.test(value);

const vinValidationSchema = {
	type: 'array',
	items: {
		type: 'string',
		pattern: '^[(A-H|J-N|P|R-Z|0-9)]{17}$',
	},
};

const useStyles = makeStyles((theme: any) => ({
	createGroup: {
		width: '100%',
		height: '100%',
		'& form': {
			borderRadius: '3px',
			boxShadow: '0px 1px 3px 0px #00000033',
			background: 'white',
			padding: '1rem',
		},
		'& h1': {
			color: '#616b70',
			fontWeight: 300,
			fontSize: '34px',
		},
	},
	textField: {
		marginBottom: '1rem !important',
		'& :focus': {
			opacity: 1,
		},
		'& input[type="file"]': {
			opacity: 0,
		},
	},
	configurationTextField: {
		fontFamily: 'NotoSansMono',
		marginBottom: '1rem',
		'& input[type="file"]': {
			opacity: 0,
		},
	},
	fileUpload: {
		position: 'relative',
	},
	uploadBtn: {
		position: 'absolute',
		right: '.5rem',
		top: '.5rem',
		textTransform: 'uppercase',
		display: 'flex',
		padding: '.7rem',
		background: ColdNeutral[200],
		color: '#ffffff',
		cursor: 'default',
		borderRadius: '.25rem',
	},
	uploadBtnCls: {
		marginRight: '8rem',
		cursor: 'pointer',
	},
	configUploadLink: {
		display: 'flex',
		width: 'fit-content',
		paddingLeft: '.25rem',
		color: theme.palette.primary.main,
		fontWeight: 600,
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	configHelperText: {
		display: 'flex',
		alignItems: 'center',
	},
	pageTitle: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	bottomArea: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
	},
	cancelButton: {
		marginRight: '1rem !important',
	},
	loading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		backgroundColor: 'rgba(255, 255, 255, 0.7)',
		zIndex: 2,
	},
	deleteButton: {
		top: '100%',
		transform: 'translateY(calc(-100% - 1rem))',
		'&:hover': {
			backgroundColor: theme.palette.error.main,
			boxShadow: 'none',
		},
	},
}));

const toastConfig = {
	autoClose: 3000,
	position: toast.POSITION.BOTTOM_LEFT,
};

// This is for the input, and will not be in the store
export type IGroup = {
	name: string;
	vins: string;
	configuration: string;
	code?: any;
	codeFileName?: string;
	default?: boolean;
	versions: Version[];
};

const initialGroupState: IGroup = {
	name: '',
	configuration: JSON.stringify(newGroupConfigurationTemplate, null, 2),
	vins: '',
	versions: [],
};
const groupToEdit = { ...initialGroupState };

enum ConfirmActions {
	LEAVE_SCREEN,
	SAVE_GROUP,
	DELETE_GROUP,
	SET_CONFIGURATION,
}

type ConfirmProps = {
	title: string;
	content: string;
	action?: ConfirmActions;
	event?: Record<string, any>;
};

export type ErrorProps = {
	[key: string]: string | null;
};

const EditGroup = () => {
	const classes = useStyles();

	const [group, setGroup] = useState<IGroup>(initialGroupState);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [confirmContent, setConfirmContent] = useState<ConfirmProps>({ title: '', content: '' });
	const [error, setError] = useState<ErrorProps>({});
	const [loadingGroup, setLoading] = useState<Boolean>(false);
	const [originalName, setOriginalName] = useState<string>('');
	const [originalCodeName, setOriginalCodeName] = useState<string>('');
	const [originalConfig, setOriginalConfig] = useState<string>('');
	const [customerCodeS3Key, setCustomerCodeS3Key] = useState<string | undefined>();
	const dispatch = useDispatch();
	const groups = useSelector<IStore, Group[]>((state) => state.groups.list);
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const history = useHistory();
	const configRef = useRef<HTMLDivElement>(null);
	const configInputRef = useRef<HTMLInputElement>(null);
	const fileRef = useRef<HTMLDivElement>(null);
	const { groupId } = useParams<any>();
	const [versionList, setVersionList] = useState<ListData[]>([]);
	const [selectedId, setSelectedId] = React.useState<string | undefined>(undefined);

	useEffect(() => {
		if (groups.length === 0) {
			dispatch(listGroupsAction());
		}
		if (groupId) {
			setLoading(true);
			getGroup(groupId)
				.then((_group) => {
					const codeFilmeName = _group.customerCodeS3Key
						? _group.customerCodeS3Key.substring(_group.customerCodeS3Key.lastIndexOf('/') + 1)
						: '';
					groupToEdit.name = _group.name;
					groupToEdit.configuration =
						_group.configuration || JSON.stringify(newGroupConfigurationTemplate, null, 2);
					groupToEdit.vins = _group.devices.join('\n');
					groupToEdit.codeFileName = codeFilmeName;
					groupToEdit.default = _group.default;
					const versions = _group.versions
						? _group.versions.map((v: Version, index: number) => {
								const relative = moment(v.lastModified).fromNow();
								const primary = `applied ${relative}`;
								const secondary = index === 0 ? 'Latest version' : undefined;
								const tooltipText = dateFormat(
									new Date(v.lastModified).toISOString(),
									'mmm dd, yyyy • HH:MM:ss',
								);
								return { id: v.versionId, primary, secondary, tooltipText };
						  })
						: [];
					const latestVersionId =
						_group.versions && _group.versions.length > 0 ? _group.versions[0].versionId : undefined;

					setVersionList(versions);
					setSelectedId(latestVersionId);
					setGroup({ ...groupToEdit });
					setOriginalName(_group.name);
					setOriginalCodeName(codeFilmeName);
					setOriginalConfig(groupToEdit.configuration);
					setCustomerCodeS3Key(_group.customerCodeS3Key);
				})
				.catch((error) => {
					toast.error(error.message, toastConfig);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [dispatch, groups, groupId]);

	const handleTextChange = ({ target }: any) => {
		if (error[target.name]) {
			setError((prev) => ({ ...prev, [target.name]: null }));
		}
		setGroup((prev) => ({ ...prev, [target.name]: target.value }));
	};

	const openSetConfigConfirmation = (selectedId: string) => {
		// @ts-ignore
		if (originalConfig !== configRef.current?.children[1].children[0]?.value) {
			setConfirmContent({
				title: 'Load a Configuration',
				content: 'Do you want to override the current configuration?',
				action: ConfirmActions.SET_CONFIGURATION,
				event: { selectedId },
			});
			setConfirmOpen(true);
		} else {
			handleConfigVersionChange(selectedId);
		}
	};

	const handleConfigVersionChange = async (newSelectedId: string) => {
		if (groupId) {
			try {
				setLoading(true);
				setSelectedId(newSelectedId);
				const { configuration } = await getConfigurationVersion(groupId, newSelectedId);
				setGroup({ ...group, configuration });
				setOriginalConfig(configuration);
			} finally {
				setLoading(false);
			}
		}
	};

	const clearFileUpload = () => {
		if (fileRef.current) {
			console.log(fileRef.current.children[1].children[0]);
			//@ts-ignore
			fileRef.current.children[1].children[0].value = '';
		}
		setGroup((prev) => ({ ...prev, code: undefined, codeFileName: undefined }));
		setOriginalCodeName('');
		setCustomerCodeS3Key(undefined);
	};

	const openFileSelector = () => {
		if (fileRef.current) {
			console.log(fileRef.current.children[1].children[0]);
			//@ts-ignore
			fileRef.current.children[1].children[0].click();
		}
	};

	const handleFileUpload = ({ target }: any) => {
		const file = target.files[0];
		if (target.name === 'configuration') {
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.result) {
					const configuration = reader.result.toString();
					setGroup((prev) => ({ ...prev, configuration }));
					configRef.current?.focus();
				}
			};
			try {
				reader.readAsText(file);
			} catch (error) {
				toast.error(`Error loading file: ${error}`, toastConfig);
			}
		} else {
			if (fileRef.current?.children && file?.name) {
				setGroup((prev) => ({ ...prev, code: file, codeFileName: file.name }));
			} else {
				setGroup((prev) => ({ ...prev, code: null, codeFileName: '' }));
			}
		}
	};

	const setErrorMessage = (errors: any[]) => {
		let message = `${errors[errors.length - 1].instancePath.substring(1) || 'configuration'} `;
		message += errors[errors.length - 1].message as string;
		const allowedValues = errors[errors.length - 1].params.allowedValues;
		if (allowedValues && allowedValues.length) {
			message += `: ${allowedValues.join(', ')}`;
		}
		setError((prev) => ({ ...prev, configuration: cap(message) }));
	};

	const saveGroup = (group: IGroup) => {
		const { name, configuration, vins } = group;
		const devices = vins
			.split('\n')
			.map((v) => v.trim())
			.filter((v) => !!v);
		const areVinsValid = ajv.validate(vinValidationSchema, devices);

		if (devices.length && !areVinsValid) {
			setError((prev) => ({ ...prev, vins: `You must provide 1 VIN per line and ${ajv.errors![0].message}` }));
			return;
		}

		try {
			const configObj = JSON.parse(group.configuration);
			const executeAction = groupId ? updateGroupAction : createGroupAction;
			const isConfigurationValid = ajv.validate(configSchema, configObj);
			if (isConfigurationValid) {
				const form = new FormData();
				form.append('id', groupId || '');
				form.append('name', encodeURI(name));
				form.append('configuration', encodeURI(configuration));
				form.append('devices', encodeURI(JSON.stringify(devices)));
				if (group.code) {
					form.append('code', group.code, group.code.name);
				} else if (customerCodeS3Key) {
					form.append('customerCodeS3Key', customerCodeS3Key);
				}
				dispatch<any>(executeAction(form))
					.then(() => {
						toast.success(`Group ${name} successfully saved!`, toastConfig);
						history('/groups');
						dispatch(listGroupsAction());
					})
					.catch(() => {
						toast.error('Saving group failed!', toastConfig);
					});
			} else {
				const errors = ajv.errors;
				if (errors && errors.length) {
					setErrorMessage(errors);
				}
			}
		} catch (error: any) {
			setError((prev) => ({ ...prev, configuration: error.message }));
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		const areAllFieldsFilled = !(isEmpty(group.name) || isEmpty(group.configuration));
		const groupsToCheckVins = groupId ? groups.filter((g) => g.id !== groupId) : groups;
		const areAllVinsUnique = groupsToCheckVins.every((g) =>
			g.devices.every((device: any) => group.vins.split('\n').every((vin) => vin !== device.vin)),
		);
		const isConfigurationTemplate: boolean = group.configuration === initialGroupState.configuration;
		if (areAllFieldsFilled && areAllVinsUnique && !isConfigurationTemplate) {
			saveGroup(group);
		} else if (!areAllFieldsFilled) {
			if (isEmpty(group.name)) {
				setError((prev) => ({ ...prev, name: 'Name is required' }));
			}
			if (isEmpty(group.configuration)) {
				setError((prev) => ({ ...prev, configuration: 'Configuration is required' }));
			}
		} else if (!areAllVinsUnique) {
			setConfirmContent({
				title: 'Duplicate VIN',
				content: 'One or more VINs are already in a group. Do you want to move it to this new group?',
				action: ConfirmActions.SAVE_GROUP,
			});
			setConfirmOpen(true);
		} else if (isConfigurationTemplate) {
			setError((prev: ErrorProps) => ({
				...prev,
				configuration: 'Please edit the provided configuration template',
			}));
		}
	};

	const handleCancel = (isConfirmed: boolean | null = null) => {
		const areAllFieldsEmpty = isEmpty(group.name) && isEmpty(group.configuration);
		const areNoChanges = groupId && JSON.stringify(groupToEdit) === JSON.stringify(group);
		if (areAllFieldsEmpty || isConfirmed || areNoChanges) {
			setGroup(initialGroupState);
			history(-1);
		} else {
			if (isConfirmed === null) {
				setConfirmContent({
					title: 'Cancel Group Creation',
					content: 'Cancel group creation/edit and discard all changes?',
					action: ConfirmActions.LEAVE_SCREEN,
				});
				setConfirmOpen(true);
			}
		}
	};

	const handleDelete = () => {
		setLoading(true);
		deleteGroup(groupId || '')
			.then((_group) => {
				toast.success(`Group successfully deleted!`, toastConfig);
				history('/groups');
				dispatch(listGroupsAction());
			})
			.catch((error) => {
				toast.error(error.message, toastConfig);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const openDeleteConfirmation = () => {
		setConfirmContent({
			title: 'Delete Group',
			content: 'Do you want to delete this group?',
			action: ConfirmActions.DELETE_GROUP,
		});
		setConfirmOpen(true);
	};

	const handleCloseConfirm = (answer: string) => {
		const isConfirmed = answer === 'yes';
		if (confirmContent.action === ConfirmActions.SAVE_GROUP && isConfirmed) {
			saveGroup(group);
		} else if (confirmContent.action === ConfirmActions.DELETE_GROUP) {
			if (isConfirmed) handleDelete();
			else setConfirmOpen(false);
		} else if (confirmContent.action === ConfirmActions.SET_CONFIGURATION && confirmContent.event?.selectedId) {
			if (isConfirmed) handleConfigVersionChange(confirmContent.event.selectedId);
		} else {
			handleCancel(isConfirmed);
		}
		setConfirmOpen(false);
	};

	return (
		<Layout>
			<div className={classes.createGroup}>
				<div className={classes.pageTitle}>
					<h1>{groupId ? `Editing ${originalName}` : 'New Group'}</h1>
					{groupId ? (
						<span>
							<Button
								type="button"
								variant="contained"
								className={classes.deleteButton}
								onClick={openDeleteConfirmation}
								startIcon={<RemoveWhiteIcon />}
								disabled={group?.default}
								color="error"
							>
								Delete Group
							</Button>
						</span>
					) : null}
				</div>
				<form onSubmit={handleSubmit}>
					{loadingGroup ? (
						<div className={classes.loading}>
							<Loading />
						</div>
					) : null}
					<TextField
						className={classes.textField}
						variant="outlined"
						fullWidth={true}
						label="Name"
						name="name"
						value={group.name}
						inputProps={{ maxLength: 200 }}
						error={!!error.name}
						helperText={error.name ? error.name : 'Name your group for future reference'}
						disabled={group?.default}
						onChange={handleTextChange}
					/>
					<div className={classes.fileUpload}>
						<TextField
							ref={fileRef}
							className={classes.textField}
							variant="outlined"
							fullWidth={true}
							type="file"
							label={
								group.codeFileName
									? group.codeFileName
									: originalCodeName
									? originalCodeName
									: 'Upload file'
							}
							InputLabelProps={{ shrink: false }}
							name="codeFile"
							inputProps={{ accept: '.zip' }}
							helperText="Please upload a bundle zip file with your functions"
							onChange={handleFileUpload}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										className={classes.uploadBtnCls}
										onClick={clearFileUpload}
									>
										<Clear />
									</InputAdornment>
								),
							}}
						/>
						<div className={classes.uploadBtn} onClick={openFileSelector}>
							Select file
						</div>
					</div>
					<TextField
						className={classes.textField}
						variant="outlined"
						fullWidth={true}
						label="VINs"
						multiline={true}
						rows={5}
						name="vins"
						value={group.vins}
						error={!!error.vins}
						helperText={error.vins ? error.vins : 'One VIN per line'}
						onChange={handleTextChange}
					/>
					<div style={{ position: 'relative' }}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<TextField
								ref={configRef}
								className={classes.configurationTextField}
								variant="outlined"
								fullWidth={true}
								label="Configuration"
								multiline={true}
								rows={10}
								name="configuration"
								value={group.configuration}
								error={!!error.configuration}
								helperText={
									error.configuration ? (
										error.configuration
									) : (
										<div className={classes.configHelperText}>
											Paste in the configuration for this group or{' '}
											<span
												onClick={() => configInputRef.current?.click()}
												className={classes.configUploadLink}
												style={{
													position: 'static',
													height: 'fit-content',
													color: 'var(--pi-labs-blue)',
												}}
											>
												load it from a file
											</span>
										</div>
									)
								}
								onChange={handleTextChange}
							/>
							{groupId ? (
								<ChangeHistoryList
									listData={versionList}
									title={'Change History'}
									onChangeSelection={openSetConfigConfirmation}
									selectedId={selectedId}
								/>
							) : undefined}
						</div>
						<input
							name="configuration"
							ref={configInputRef}
							accept=".txt,.json"
							onChange={handleFileUpload}
							hidden={true}
							type="file"
						/>
					</div>
					<div className={classes.bottomArea}>
						<Button onClick={() => handleCancel()} color="primary" className={classes.cancelButton}>
							Cancel
						</Button>
						<Button
							type="submit"
							startIcon={loading ? <Spinner primaryColor="white" size={1.5} /> : <SaveWhiteIcon />}
							color="primary"
							variant="contained"
						>
							Save and apply
						</Button>
					</div>
				</form>
				<ConfirmDialog
					title={confirmContent.title}
					content={confirmContent.content}
					handleClose={handleCloseConfirm}
					open={confirmOpen}
				/>
			</div>
		</Layout>
	);
};

export default EditGroup;
