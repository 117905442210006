import axios from './interceptors';
import { BASE_URL } from './constants';

export const uploadProtocol = async (protocol: FormData) => {
	return axios
		.post(`${BASE_URL}/api/v1/installation/protocol`, protocol)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};
