import React from 'react';
// file UserIcon.tsx
import { SvgIcon } from '@mui/material';

// type IconProps = {
// 	name: string;
// 	color?: string;
// 	animation?: string;
// };

// const Icon = ({name, color, animation}: IconProps) => {
// 	return (
// 		<box-icon name={name} color={color ? color : "#3C494F"} size={"24px"} animation={animation ? animation : ""} />
// 	)
// }

const PlusIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" />
		</SvgIcon>
	);
};

const EditIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M19.045 7.401C19.423 7.023 19.631 6.521 19.631 5.987C19.631 5.453 19.423 4.951 19.045 4.573L17.459 2.987C17.081 2.609 16.579 2.401 16.045 2.401C15.511 2.401 15.009 2.609 14.632 2.986L4 13.585V18H8.413L19.045 7.401ZM16.045 4.401L17.632 5.986L16.042 7.57L14.456 5.985L16.045 4.401ZM6 16V14.415L13.04 7.397L14.626 8.983L7.587 16H6ZM4 20H20V22H4V20Z" />
		</SvgIcon>
	);
};

const FilterIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M7 10.9999H17V12.9999H7V10.9999ZM4 6.99994H20V8.99994H4V6.99994ZM10 14.9999H14V16.9999H10V14.9999Z" />
		</SvgIcon>
	);
};

const PlusWhiteIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" />
		</SvgIcon>
	);
};

const RemoveWhiteIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M5.00293 20.3973C5.00293 21.5003 5.89993 22.3973 7.00293 22.3973H17.0029C18.1059 22.3973 19.0029 21.5003 19.0029 20.3973V8.39728H21.0029V6.39728H18.0029H17.0029V4.39728C17.0029 3.29428 16.1059 2.39728 15.0029 2.39728H9.00293C7.89993 2.39728 7.00293 3.29428 7.00293 4.39728V6.39728H6.00293H3.00293V8.39728H5.00293V20.3973ZM9.00293 4.39728H15.0029V6.39728H9.00293V4.39728ZM8.00293 8.39728H16.0029H17.0029L17.0039 20.3973H7.00293V8.39728H8.00293Z" />
			<path d="M9.00293 10.3973H11.0029V18.3973H9.00293V10.3973ZM13.0029 10.3973H15.0029V18.3973H13.0029V10.3973Z" />
		</SvgIcon>
	);
};

const SaveWhiteIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M5 21H19C20.103 21 21 20.103 21 19V8C21 7.735 20.895 7.48 20.707 7.293L16.707 3.293C16.52 3.105 16.266 3 16 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21ZM15 19H9V14H15V19ZM13 7H11V5H13V7ZM5 5H7V9H9H11H13H14H15V5H15.586L19 8.414L19.001 19H17V14C17 12.897 16.103 12 15 12H9C7.897 12 7 12.897 7 14V19H5V5Z" />
		</SvgIcon>
	);
};

const LoadingIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M12 21.9999C13.1046 21.9999 14 21.1045 14 19.9999C14 18.8954 13.1046 17.9999 12 17.9999C10.8954 17.9999 10 18.8954 10 19.9999C10 21.1045 10.8954 21.9999 12 21.9999Z" />
			<path d="M12 5.99994C13.1046 5.99994 14 5.10451 14 3.99994C14 2.89537 13.1046 1.99994 12 1.99994C10.8954 1.99994 10 2.89537 10 3.99994C10 5.10451 10.8954 5.99994 12 5.99994Z" />
			<path d="M6.34299 19.6569C7.44756 19.6569 8.34299 18.7615 8.34299 17.6569C8.34299 16.5524 7.44756 15.6569 6.34299 15.6569C5.23842 15.6569 4.34299 16.5524 4.34299 17.6569C4.34299 18.7615 5.23842 19.6569 6.34299 19.6569Z" />
			<path d="M17.657 8.34296C18.7616 8.34296 19.657 7.44753 19.657 6.34296C19.657 5.23839 18.7616 4.34296 17.657 4.34296C16.5524 4.34296 15.657 5.23839 15.657 6.34296C15.657 7.44753 16.5524 8.34296 17.657 8.34296Z" />
			<path d="M3.99999 14.001C5.10511 14.001 6.00099 13.1051 6.00099 12C6.00099 10.8948 5.10511 9.99896 3.99999 9.99896C2.89487 9.99896 1.99899 10.8948 1.99899 12C1.99899 13.1051 2.89487 14.001 3.99999 14.001Z" />
			<path d="M20 13.9999C21.1046 13.9999 22 13.1045 22 11.9999C22 10.8954 21.1046 9.99994 20 9.99994C18.8954 9.99994 18 10.8954 18 11.9999C18 13.1045 18.8954 13.9999 20 13.9999Z" />
			<path d="M6.34299 8.34393C7.44756 8.34393 8.34299 7.4485 8.34299 6.34393C8.34299 5.23936 7.44756 4.34393 6.34299 4.34393C5.23842 4.34393 4.34299 5.23936 4.34299 6.34393C4.34299 7.4485 5.23842 8.34393 6.34299 8.34393Z" />
			<path d="M17.657 19.658C18.7616 19.658 19.657 18.7625 19.657 17.658C19.657 16.5534 18.7616 15.658 17.657 15.658C16.5524 15.658 15.657 16.5534 15.657 17.658C15.657 18.7625 16.5524 19.658 17.657 19.658Z" />
		</SvgIcon>
	);
};

const LoadingWhiteIcon = () => {
	return <LoadingIcon name="loader-circle" color={'white'} animation="spin" />;
};

const CheckWhiteIcon = (props: any) => {
	return <CheckIcon color={'white'} />;
};

const CheckIcon = (props: any) => {
	return (
		<SvgIcon {...props} name="check-circle" color={'white'}>
			<path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z" />
			<path d="M9.99896 13.587L7.69996 11.292L6.28796 12.708L10.001 16.413L16.707 9.70697L15.293 8.29297L9.99896 13.587Z" />
		</SvgIcon>
	);
};

const ErrorIcon = (props: any) => {
	return (
		<SvgIcon {...props} name="error" color={'red'}>
			<path d="M11.001 9.99994H13.001V14.9999H11.001V9.99994ZM11 15.9999H13V17.9999H11V15.9999Z" />
			<path d="M13.768 4.19994C13.42 3.54494 12.742 3.13794 12 3.13794C11.258 3.13794 10.58 3.54494 10.232 4.20094L2.89401 18.0639C2.56301 18.6899 2.58301 19.4249 2.94801 20.0319C3.31301 20.6379 3.95301 20.9999 4.66101 20.9999H19.339C20.047 20.9999 20.688 20.6379 21.053 20.0319C21.417 19.4259 21.438 18.6899 21.107 18.0639L13.768 4.19994ZM4.66101 18.9999L12 5.13694L19.344 18.9999H4.66101Z" />
		</SvgIcon>
	);
};

const ErrorCircleIcon = (props: any) => {
	return (
		<SvgIcon {...props} name="error-circle" color={'red'}>
			<path d="M11.953 1.99994C6.465 1.99994 2 6.48594 2 11.9999C2 17.5139 6.486 21.9999 12 21.9999C17.514 21.9999 22 17.5139 22 11.9999C22 6.48594 17.493 1.99994 11.953 1.99994ZM12 19.9999C7.589 19.9999 4 16.4109 4 11.9999C4 7.58894 7.567 3.99994 11.953 3.99994C16.391 3.99994 20 7.58894 20 11.9999C20 16.4109 16.411 19.9999 12 19.9999Z" />
			<path d="M11 6.99994H13V13.9999H11V6.99994ZM11 14.9999H13V16.9999H11V14.9999Z" />
		</SvgIcon>
	);
};
const XIcon = (props: any) => {
	return (
		<SvgIcon {...props} name="x">
			<path d="M16.5091 6.34399L12.2661 10.586L8.02411 6.34399L6.61011 7.75799L10.8521 12L6.61011 16.242L8.02411 17.656L12.2661 13.414L16.5091 17.656L17.9231 16.242L13.6811 12L17.9231 7.75799L16.5091 6.34399Z" />
		</SvgIcon>
	);
};

const RotateRightIcon = (props: any) => {
	return (
		<SvgIcon {...props} style={{ color: props.color || '#92A1B4' }}>
			<path d="M16.242 17.242a6.04 6.04 0 0 1-1.37 1.027l.961 1.754a8.068 8.068 0 0 0 2.569-2.225l-1.6-1.201a5.938 5.938 0 0 1-.56.645zm1.743-4.671a5.975 5.975 0 0 1-.362 2.528l1.873.701a7.977 7.977 0 0 0 .483-3.371l-1.994.142zm1.512-2.368a8.048 8.048 0 0 0-1.841-2.859l-1.414 1.414a6.071 6.071 0 0 1 1.382 2.146l1.873-.701zm-8.128 8.763c-.047-.005-.094-.015-.141-.021a6.701 6.701 0 0 1-.468-.075 5.923 5.923 0 0 1-2.421-1.122 5.954 5.954 0 0 1-.583-.506 6.138 6.138 0 0 1-.516-.597 5.91 5.91 0 0 1-.891-1.634 6.086 6.086 0 0 1-.247-.902c-.008-.043-.012-.088-.019-.131A6.332 6.332 0 0 1 6 13.002V13c0-1.603.624-3.109 1.758-4.242A5.944 5.944 0 0 1 11 7.089V10l5-4-5-4v3.069a7.917 7.917 0 0 0-4.656 2.275A7.936 7.936 0 0 0 4 12.999v.009c0 .253.014.504.037.753.007.076.021.15.03.227.021.172.044.345.076.516.019.1.044.196.066.295.032.142.065.283.105.423.032.112.07.223.107.333.026.079.047.159.076.237l.008-.003A7.948 7.948 0 0 0 5.6 17.785l-.007.005c.021.028.049.053.07.081.211.272.433.538.681.785a8.236 8.236 0 0 0 .966.816c.265.192.537.372.821.529l.028.019.001-.001a7.877 7.877 0 0 0 2.136.795l-.001.005.053.009c.201.042.405.071.61.098.069.009.138.023.207.03a8.038 8.038 0 0 0 2.532-.137l-.424-1.955a6.11 6.11 0 0 1-1.904.102z" />
		</SvgIcon>
	);
};

export {
	FilterIcon,
	EditIcon,
	PlusIcon,
	PlusWhiteIcon,
	RemoveWhiteIcon,
	SaveWhiteIcon,
	LoadingIcon,
	LoadingWhiteIcon,
	CheckWhiteIcon,
	CheckIcon,
	ErrorIcon,
	ErrorCircleIcon,
	XIcon,
	RotateRightIcon,
};
