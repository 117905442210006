import React, { useState } from 'react';
import Device, { VinDevice } from '../../model/Device';
import { ColumnConfig, Table, RowData, Tooltip } from '@easerill/pixida-group-ui';
import { RotateRightIcon } from '../material-table/icons';
import AlertDialog from './AlertDialog';
import { getVinConfiguration } from '../../api/groups';

export type GroupListDetailsProps = {
	devices: (Device | VinDevice | string)[];
	updatedDevices?: string[];
};

type DeviceDetails = {
	vin: string;
	pendingUpdate: boolean;
};

const GroupListDetails = ({ devices, updatedDevices }: GroupListDetailsProps) => {
	const [confirmOpen, setConfigDialogOpen] = useState(false);
	const [dialogTextContent, setDialogTextContent] = useState('');
	const [dialogTextTitle, setDialogTextTitle] = useState('');
	const [loadingVinConfiguration, setLoading] = useState<Boolean>(false);
	const tableData: DeviceDetails[] = devices.map((device: Device | VinDevice | string) => {
		const deviceDetails: DeviceDetails = {
			vin: '',
			pendingUpdate: false,
		};
		if (typeof device === 'string') {
			deviceDetails.vin = device;
		} else {
			deviceDetails.vin = device.vin || '';
		}

		deviceDetails.pendingUpdate = updatedDevices && updatedDevices.includes(deviceDetails.vin) ? false : true;

		return deviceDetails;
	});

	async function openConfigDialog(vin: string) {
		setLoading(true);
		try {
			const configuration = await getVinConfiguration(vin);
			setDialogTextTitle(`Device ${vin} Configuration`);
			setDialogTextContent(JSON.stringify(configuration, null, '\t'));
			setConfigDialogOpen(true);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	}

	const columnsConfig: readonly ColumnConfig<DeviceDetails>[] = [
		{
			id: 'vin',
			label: 'VIN',
			align: 'left',
			render: (rowData: RowData<DeviceDetails>) => (
				<div style={{ cursor: 'pointer' }} onClick={() => openConfigDialog(rowData.data.vin)}>
					{rowData.data.vin}
				</div>
			),
		},
		{
			id: 'pendingUpdate',
			label: '',
			align: 'right',
			paddingRight: '40px',
			render: (rowData: RowData<DeviceDetails>) =>
				rowData.data.pendingUpdate ? (
					<Tooltip title="Ongoing update">
						<span>
							<RotateRightIcon />
						</span>
					</Tooltip>
				) : undefined,
		},
	];

	function handlerClose() {
		setConfigDialogOpen(false);
		setDialogTextTitle('');
		setDialogTextContent('');
	}

	return (
		<>
			<Table
				isLoading={loadingVinConfiguration.valueOf()}
				title="Devices"
				columnsConfig={columnsConfig}
				data={tableData}
				disablePagination
				showBorder={false}
			/>
			<AlertDialog
				title={dialogTextTitle}
				textContent={dialogTextContent}
				handleClose={handlerClose}
				open={confirmOpen}
				fullWidth={true}
				maxWidth={'xl'}
			/>
		</>
	);
};

export default GroupListDetails;
